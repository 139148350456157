import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import main from './layouts/main.vue'
import def from './layouts/default.vue'
import store from './plugins/store'
import axios from 'axios'

let baseurl = process.env?.NODE_ENV == 'development' ? 'http://localhost:3030' : '/api'

let api = axios.create({
  baseURL: baseurl,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
let isimage = (url) => {
  let valit = ["png", "jpeg"];
  if (!url.includes("https") || !url.includes("http")) return false;
  if (!url) return false;
  let urlx;
  try {
    urlx = new URL(url);
  } catch (e) {
    return false;
  }
  urlx.pathname;
  let filename = urlx.pathname.split("/").reverse()[0];
  var re = /(?:\.([^.]+))?$/;

  var ext = re.exec(filename)[1];
  return valit.includes(ext);
}
Vue.prototype.$http = api;
Vue.prototype.$isimage = isimage;
Vue.config.productionTip = false
Vue.component('main-layout', main)
Vue.component('default-layout', def)



new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
