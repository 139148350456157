<template>
  <div class="">
    <v-sheet color="red darken-2" height="70" style="width: 100%">
      <v-container>
        <v-layout class="mt-1">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">
              Ticket with {{ users[owner].username }}
            </p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container>
      <v-row class="fill-height pb-14" align="end">
        <v-col>
          <div
            v-for="(item, index) of groupedPosts()"
            :key="index"
            :class="[
              'd-flex align-center my-2',
              item[0].author == owner ? 'justify-end' : null,
            ]"
          >
            <div v-if="item[0].author == owner" class="text-end fluid">
              <div v-for="(msg, index) of item" :key="index" class="fluid">
                <div
                  :class="
                    `mr-3 px-3 py-3 mb-3 rounded-t-lg h-10 text-wrap white--text d-inline-flex ` +
                    (index == item.length - 1
                      ? 'rounded-bl-lg'
                      : 'rounded-b-lg')
                  "
                  style="max-width: 40vh; background-color: #2196f3"
                >
                  {{ msg.content }}
                  <div
                    v-for="(attachments, index) of msg.attachments"
                    :key="index"
                  >
                    <img
                      :src="attachments.attachment"
                      style="
                        width: 100px;
                        height: 100px;
                        border-radius: 10px;
                        margin: 10px;
                      "
                    />
                  </div>
                </div>
              </div>
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  v-bind="attrs"
                  v-on="on"
                  :color="item.from == 'user' ? 'indigo' : 'red'"
                  class="mt-auto mb-3"
                  size="36"
                >
                  <img :src="users[item[0].author].avatarURL" />
                </v-avatar>
              </template>
              <span
                >{{users[item[0].author].tag}}</span
              >
            </v-tooltip>

            <div v-if="item[0].author != owner" class="fluid">
              <div v-for="(msg, index) of item" :key="index" class="fluid">
                <div
                  :class="
                    `ml-3 px-3 py-3 mb-3 rounded-t-lg h-10 text-wrap white--text d-inline-flex ` +
                    (index == item.length - 1
                      ? 'rounded-br-lg'
                      : 'rounded-b-lg')
                  "
                  style="max-width: 40vh; background-color: #ce1313"
                >
                  {{ msg.content }}
                  <br />
                  <div
                    class=""
                    v-for="(attachments, index) of msg.attachments"
                    :key="index"
                  >
                    <img
                      :src="attachments.attachment"
                      style="
                        border-radius: 2px;
                        width: 100px;
                        height: 100px;
                        margin: 10px;
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    clicks: 0,
    data: [],
    users: {},
    owner: "",
  }),

  mounted() {
    this.$http.get(`/tickets/${this.$route.params.id}`).then((res) => {
      console.log(res.data);
      this.data = res.data.messages;
      this.users = res.data.users;
      this.owner = res.data.owner;
    });
  },
  methods: {
    groupedPosts() {
      //write an algorithm to group posts by author in form of an array
      let grouped = [];
      let temp = [];
      let prev = null;
      this.data.forEach((item) => {
        if (prev == null) {
          prev = item.author;
          temp.push(item);
        } else if (prev == item.author) {
          temp.push(item);
        } else {
          grouped.push(temp);
          temp = [];
          temp.push(item);
          prev = item.author;
        }
      });

      return grouped;
    },
  },
  components: {},
};
</script>
