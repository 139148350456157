<template>
  <div>
    <div v-if="count === 5" style="width: 100%; min-height: 100%; box-sizing: border-box; ">
      <v-img
        src="https://styles.redditmedia.com/t5_2r5i1/styles/communityIcon_x4lqmqzu1hi81.jpg"
        class="mx-auto mt-7 mb-3"
      ></v-img>
    </div>
    <div v-if="count !== 5">
      <v-app-bar v-if="isMobile()" color="#f7f7f7" flat absolute app dark>
        <v-app-bar-nav-icon
          color="black"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer
        :permanent="!isMobile()"
        rail
        class="transition-slide"
        :temporary="isMobile()"
        v-model="drawer"
        :mini-variant.sync="mini"
        :expand-on-hover="!isMobile()"
        left
        app
      >
        <v-list dense shaped>
          <v-list-item link :to="`/profile/${$store.state.user.roblox}`">
            <v-list-item-avatar color="red darken-2" class="ml-n2 my-auto">
              <v-img :src="$store.state.user.pfp"></v-img>
            </v-list-item-avatar>
            <v-list-item-title class="text-h6">
              {{ $store.state.user.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" link class="mt-2">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="dark" link class="mt-2">
            <v-list-item-icon>
              <v-icon>{{
                $vuetify.theme.dark ? "mdi-white-balance-sunny" : "mdi-weather-night"
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $vuetify.theme.dark ? "Light mode" : "Dark mode"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mt-2 mb-2"> </v-divider>

          <v-list-item link :to="`/`">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mt-2"> </v-divider>

          <div v-for="page in pages" :key="page.path">
            <v-list-item
              link
              class="mt-2"
              v-if="
                page.permission ? $store.state.user.permission >= page.permission : true
              "
              :to="page.path"
            >
              <v-list-item-icon>
                <v-icon>{{ page.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ page.name }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list-item
            link
            v-if="$store.state.user.isDeveloper"
            class="mt-2"
            :to="`/developer`"
          >
            <v-list-item-icon>
              <v-icon>mdi-hammer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Developer</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    count: 0,
    mini: true,
    pages: [
      {
        name: "Bans",
        icon: "mdi-hammer",
        path: "/ban",
        permission: 4,
      },
      {
        name: "Group bans",
        icon: "mdi-account-multiple",
        path: "/gban",
        permission: 4,
      },
      {
        name: "Lookup",
        icon: "mdi-magnify",
        path: "/lookup",
        permission: 1,
      },
      {
        name: "Images",
        icon: "mdi-image",
        path: "/images",
        permission: 1,
      },
      {
        name: "Your reports",
        icon: "mdi-clipboard-outline",
        path: "/reports",
        permission: 1,
      },
      {
        name: "Game",
        icon: "mdi-gamepad",
        path: "/game",
        permission: 2,
      }, {
        name: "Admin reports",
        icon: "mdi-clipboard-list",
        path: "/areports",
        permission: 4,
      },
      {
        name: "Admin",
        icon: "mdi-cog",
        path: "/admin",
        permission: 5,
      },
    ],
  }),
  methods: {
    isSmall: function () {
      return window.innerWidth < 600;
    },
    fetchusername() {
      return this.$store.state.user;
    },
    goto: function (url) {
      window.open(url, "_blank");
    },
    logout() {
      this.$router.push("/signin");
      this.$cookies.remove("connect.sid");
    },
    dark() {
      this.count++;
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },
    isMobile: function () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.mini = false;
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.fixedBottom {
  position: fixed !important;
  bottom: 10px !important;
  width: 100%;
}
</style>
