<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-card class="mb-3 pb-5" :loading="editing.loading">
        <v-card-text> User editing </v-card-text>
        <div v-if="!editing.isEditing">
          <v-text-field
            v-model="editing.username"
            label="Username"
            outlined
            class="mx-4"
            v-if="!editing.isEditing"
            hide-details="auto"
            required
          ></v-text-field>

          <v-row class="mx-4 mt-3">
            <v-btn
              text
              class="ml-auto"
              @click="getData"
              :disabled="editing.loading"
              color="info"
            >
              Check
            </v-btn>
          </v-row>
        </div>
        <div class="mt-n5" v-if="editing.isEditing">
          <v-layout>
            <v-avatar class="my-auto ml-4 mr-n2" size="25">
              <v-img :src="editing.user.pfp"></v-img>
            </v-avatar>
            <div>
              <v-card-text class="grey--text">@{{ editing.user.username }}</v-card-text>
            </div></v-layout
          >
          <v-text-field
            v-model="editing.data.gp"
            label="GP"
            @input="(s) => editing.data.gp = parseInt(s)"
            type="number"

            outlined
            class="mx-4"
            hide-details="auto"
            required
          ></v-text-field>
          <v-card class="mx-4 mt-3 " v-for="data in editing.history" :key="data.id" outlined> 
            <v-card-title> {{ new Date(data.created).toLocaleDateString() }} </v-card-title>
            <v-card-text class="mt-n4">
              {{ data.data }} GP
            </v-card-text>
          </v-card>
          <vue-json-editor
            v-model="editing.data"
            :show-btns="false"
            class="mx-4 mt-2"
            v-if="editing.isDeveloper"
            :expandedOnStart="true"
          ></vue-json-editor>
          <v-row class="mx-4 mt-3">
            <v-btn
              text
              class=""
              @click="editing.isEditing = false"
              :disabled="permissions.loading"
              color="success"
            >
              Change user
            </v-btn>
            <v-btn
              text
              class="ml-auto"
              @click="saveData"
              :disabled="permissions.loading"
              color="info"
            >
              Save
            </v-btn></v-row
          >
        </div>
      </v-card>
      <v-row>
        <v-col>
          <v-card :loading="blacklist.loading" outlined>
            <v-card-text> Blacklisting {{ blacklist.clothing ? 'clothing' : 'decals'}} </v-card-text>
            <v-form class="mx-4" ref="form">
              <v-text-field
                :disabled="blacklist.loading"
                v-model="blacklist.id"
                filled
                hide-details="auto"
                label="ID"
              >
              </v-text-field>
              <v-row class="mx-4 mt-1">
                <v-switch class="mx-auto" v-model="blacklist.clothing" label="Clothing">
                </v-switch
              ></v-row>
            </v-form>
            <v-row class="pb-6 mx-4 mt-3">
              <v-btn
                text
                class="ml-auto"
                @click="bl"
                :disabled="blacklist.loading"
                color="info"
              >
                Blacklist
              </v-btn></v-row
            ></v-card
          >
        </v-col>
      </v-row>
      <v-card class="mt-3" :loading="blacklist.loading" outlined>
        <v-card-text> Permissions </v-card-text>
        <v-form @submit.prevent="addMember" class="mx-4" ref="form">
          <v-text-field
            :disabled="permissions.loading"
            v-model="permissions.username"
            filled
            dense
            hide-details="auto"
            label="Username"
          >
          </v-text-field>
        </v-form>
        <v-row class="pb-6 mx-4 mt-3">
          <v-btn
            text
            class="ml-auto"
            @click="addMember"
            :disabled="permissions.loading"
            color="info"
          >
            Add
          </v-btn></v-row
        >
        <v-divider></v-divider>
        <v-row v-if="this.permissions.dloading" class="mx-auto mb-10 mt-10">
          <v-progress-circular
            :size="50"
            color="amber"
            class="mx-auto"
            indeterminate
          ></v-progress-circular>
          <v-card-text class="text-center mt-2 mt-n2 mb-n5"> Loading... </v-card-text>
        </v-row>
        <div v-if="!this.permissions.dloading">
          <v-list one-line v-for="(o, i) of this.permissions.data" :key="i">
            <template>
              <v-list-item>
                <v-list-item-avatar
                  style="cursor: pointer"
                  @click="$router.push(`/profile/${o.roblox}`)"
                >
                  <v-img style="cursor: pointer" :src="o.pfp"></v-img>
                </v-list-item-avatar>

                <v-list-item-content
                  style="cursor: pointer"
                  @click="$router.push(`/profile/${o.roblox}`)"
                >
                  <v-list-item-title>{{ o.displayName }}</v-list-item-title>
                  <v-list-item-subtitle>@{{ o.ruser }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-spacer> </v-spacer>
                <v-menu left bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn plain class="my-auto mr-2" v-bind="attrs" v-on="on">
                      {{ perms[o.permission] }}
                      <v-icon class="ml-n1" right dark> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="() => setPermission(o, 5)">
                      <v-list-item-title>Moderator</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => setPermission(o, 4)">
                      <v-list-item-title>Trial mod</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => setPermission(o, 3)">
                      <v-list-item-title>Observer</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => setPermission(o, 2)">
                      <v-list-item-title>Community support</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="() => setPermission(o, 1)">
                      <v-list-item-title>Regular</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
            </template>
          </v-list>
        </div></v-card
      >
    </v-container>
    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import vueJsonEditor from "vue-json-editor";

export default {
  name: "Home",

  components: { vueJsonEditor },
  data: () => ({
    loading: false,
    perms: {
      1: "Regular",
      2: "Community Support",
      3: "Observer",
      4: "Trial mod",
      5: "Moderator",
      6: "Admin",
    },
    blacklist: {
      clothing: false,
      id: "",
      loading: false,
    },
    editing: {
      isEditing: false,
      loading: false,
      username: "",
      data: {
        gp: 72000,
        rep: "",
        rawdata: {},
        history: []
      },
      user: {
        username: "ItsWHOOOP",
        pfp: "",
      },
    },
    permissions: {
      username: "",
      data: [],
      loading: false,
      dloading: false,
    },
    toast: {
      message: "",
      color: "success",
      open: false,
    },
  }),
  async created() {
    this.permissions.dloading = true;
    let req = await this.$http.get("/members");
    this.permissions.data = req.data.members;
    this.permissions.dloading = false;
  },
  methods: {
    getData() {
      this.editing.loading = true;
      this.$http.get(`/gp/${this.editing.username}`).then((res) => {
        this.editing.data = res.data.data;
        this.editing.isDeveloper = res.data?.isDeveloper;
        this.editing.history = res.data.versions;

        this.editing.user = res.data.user;
        this.editing.loading = false;
        this.editing.isEditing = true;
      });
    },
    async saveData() {
      this.editing.loading = true;
      if (this.isDeveloper) {
        this.editing.data.gp = this.editing.gp;
      }
      console.log(this.editing.data);
      await this.$http.post(`/gp/${this.editing.user.username}`, this.editing.data);
      this.editing.loading = false;
      this.toast.message = "Saved!";
      this.toast.color = "success";
      this.toast.open = true;
    },

    async bl() {
      this.blacklist.loading = true;
      try {
        await this.$http.post("/blacklist/add", {
          clothing: this.blacklist.clothing,
          id: this.blacklist.id,
        });
      } catch (e) {
        this.blacklist.loading = false;
        if (e.response.data?.message) {
          this.toast.message = e.response.data.message;
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully blacklisted item";
      this.toast.color = "success";
      this.toast.open = true;
      this.blacklist = {
        clothing: this.blacklist.clothing,
        id: "",
        loading: false,
      };
    },
    async addMember() {
      this.permissions.loading = true;
      let req;
      try {
        req = await this.$http.post("/permission", {
          username: this.permissions.username,
        });
      } catch (e) {
        this.permissions.loading = false;
        if (e.response.data?.message) {
          this.toast.message = e.response.data.message;
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.permissions.loading = false;
      this.toast.message = "Sucessfully added user";
      this.toast.color = "success";
      this.toast.open = true;
      this.permissions.data.unshift(req.data.user);
      this.permissions = this.permissions.data.sort.sort(
        (a, b) => b.permission - a.permission
      );
    },
    async setPermission(user, permission) {
      this.permissions.loading = true;
      try {
        await this.$http.patch("/permission/" + user.roblox, {
          permission: permission,
        });
      } catch (e) {
        this.permissions.loading = false;
        if (e.response.data?.message) {
          this.toast.message = e.response.data.message;
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.permissions.loading = false;
      this.toast.message = "Sucessfully changed user permission";
      this.toast.color = "success";
      this.toast.open = true;
      user.permission = permission;

      if (permission == 1) {
        this.permissions.data.splice(this.permissions.data.indexOf(user), 1);
      }
      this.permissions = this.permissions.data.sort.sort(
        (a, b) => b.permission - a.permission
      );
    },
  },
};
</script>
