<template>
    <div>
      <v-sheet color="red darken-2" height="200" style="width: 100%">
        <v-container>
          <v-layout class="mt-14">
            <div class="white--text">
              <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
              <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
            </div>
          </v-layout>
        </v-container>
      </v-sheet>
      <v-container class="mt-n16">
        
        <v-card class="mt-3" :loading="permissions.loading" outlined>
          <v-card-title class="mb-n5"> Choosen one</v-card-title>
          <v-card-text> You're the chosen one, and that means you have to choose another person before you can access GRPanel >:) </v-card-text>
          
          
          <v-row v-if="this.permissions.dloading" class="mx-auto mb-10 mt-10">
            <v-progress-circular
              :size="50"
              color="amber"
              class="mx-auto"
              indeterminate
            ></v-progress-circular>
            <v-card-text class="text-center mt-2 mt-n2 mb-n5"> Loading... </v-card-text>
          </v-row>
          <div v-if="!this.permissions.dloading">
            <v-list one-line v-for="(o, i) of this.permissions.data" :key="i">
              <template>
                <v-list-item>
                  <v-list-item-avatar
                    style="cursor: pointer"
                    @click="$router.push(`/profile/${o.roblox}`)"
                  >
                    <v-img style="cursor: pointer" :src="o.pfp"></v-img>
                  </v-list-item-avatar>
  
                  <v-list-item-content
                    style="cursor: pointer"
                    @click="$router.push(`/profile/${o.roblox}`)"
                  >
                    <v-list-item-title>{{ o.displayName }}</v-list-item-title>
                    <v-list-item-subtitle>@{{ o.username }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer> </v-spacer>
                  <v-btn color="blue" text :loading="permissions.loading" :disabled="permissions.loading" @click="setPermission(o)"> Choose </v-btn>
                </v-list-item>
              </template>
            </v-list>
          </div></v-card
        >
      </v-container>
      <v-snackbar v-model="toast.open">
        {{ toast.message }}
  
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="toast.color"
            text
            v-bind="attrs"
            timeout="2000"
            @click="toast.open = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Home",
  
    components: { },
    data: () => ({
      loading: false,

      permissions: {
        username: "",
        data: [],
        loading: false,
        dloading: false,
      },
      toast: {
        message: "",
        color: "success",
        open: false,
      },
    }),
    async created() {
      this.permissions.dloading = true;
      let req = await this.$http.get("/chosenGame/choosable");
      this.permissions.data = req.data.members.filter((o) => o.roblox !== this.$store.state.user.roblox);
      this.permissions.dloading = false;
    },
    methods: {
      async setPermission(user) {
        this.permissions.loading = true;
        try {
          await this.$http.post("/chosenGame/choose/" + user.roblox);
        } catch (e) {
          this.permissions.loading = false;
          if (e.response.data?.message) {
            this.toast.message = e.response.data.message;
            this.toast.color = "error";
            this.toast.open = true;
            return;
          }
          this.toast.message = "Something went wrong";
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
        this.permissions.loading = false;
        this.toast.message = "Its their turn now 😈";
        this.toast.color = "success";
        this.toast.open = true;
        this.$store.commit("noLongerChosenOne");
        this.$router.push("/");
      },
    },
  };
  </script>
  