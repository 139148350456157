var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-sheet',{staticStyle:{"width":"100%"},attrs:{"color":"red darken-2","height":"70"}},[_c('v-container',[_c('v-layout',{staticClass:"mt-1"},[_c('div',{staticClass:"white--text"},[_c('p',{staticClass:"text-h4 font-weight-bold"},[_vm._v(" Ticket with "+_vm._s(_vm.users[_vm.owner].username)+" ")])])])],1)],1),_c('v-container',[_c('v-row',{staticClass:"fill-height pb-14",attrs:{"align":"end"}},[_c('v-col',_vm._l((_vm.groupedPosts()),function(item,index){return _c('div',{key:index,class:[
            'd-flex align-center my-2',
            item[0].author == _vm.owner ? 'justify-end' : null ]},[(item[0].author == _vm.owner)?_c('div',{staticClass:"text-end fluid"},_vm._l((item),function(msg,index){return _c('div',{key:index,staticClass:"fluid"},[_c('div',{class:"mr-3 px-3 py-3 mb-3 rounded-t-lg h-10 text-wrap white--text d-inline-flex " +
                  (index == item.length - 1
                    ? 'rounded-bl-lg'
                    : 'rounded-b-lg'),staticStyle:{"max-width":"40vh","background-color":"#2196f3"}},[_vm._v(" "+_vm._s(msg.content)+" "),_vm._l((msg.attachments),function(attachments,index){return _c('div',{key:index},[_c('img',{staticStyle:{"width":"100px","height":"100px","border-radius":"10px","margin":"10px"},attrs:{"src":attachments.attachment}})])})],2)])}),0):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mt-auto mb-3",attrs:{"color":item.from == 'user' ? 'indigo' : 'red',"size":"36"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.users[item[0].author].avatarURL}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.users[item[0].author].tag))])]),(item[0].author != _vm.owner)?_c('div',{staticClass:"fluid"},_vm._l((item),function(msg,index){return _c('div',{key:index,staticClass:"fluid"},[_c('div',{class:"ml-3 px-3 py-3 mb-3 rounded-t-lg h-10 text-wrap white--text d-inline-flex " +
                  (index == item.length - 1
                    ? 'rounded-br-lg'
                    : 'rounded-b-lg'),staticStyle:{"max-width":"40vh","background-color":"#ce1313"}},[_vm._v(" "+_vm._s(msg.content)+" "),_c('br'),_vm._l((msg.attachments),function(attachments,index){return _c('div',{key:index},[_c('img',{staticStyle:{"border-radius":"2px","width":"100px","height":"100px","margin":"10px"},attrs:{"src":attachments.attachment}})])})],2)])}),0):_vm._e()],1)}),0)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }