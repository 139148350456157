<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">
              Hi {{ this.$store.state.user.name }},
            </p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">
              Welcome to GRPanel
            </p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-row>
        <v-col>
          <v-card :loading="sloading" outlined>
            <v-card-text> Search </v-card-text>
            <v-form @submit.prevent="searchs" ref="form">
              <v-row class="mx-4 ">
                <v-text-field
                  :disabled="sloading"
                  v-model="search.userId"
                  filled
                  @input="search.found = true"

                  class="mr-1"
                  label="User ID"
                >
                </v-text-field>
                <v-text-field
                  :disabled="sloading"
                  v-model="search.topic"
                  filled
                  @input="search.found = true"
                  class="ml-1"
                  label="Topic"
                >
                </v-text-field>
              </v-row>
              
            </v-form>
            <v-row class="mx-4 mt-n3 pb-n3">
                <v-switch class="mx-auto" v-model="search.transcripted" label="Transcripted only?">
                </v-switch
              ></v-row>
            <v-row class="pb-7 mx-4">
              <v-btn 
              text 
              class="ml-auto"
              @click="clearSearch"
              :disabled="!search.active"
              color="red"
              >
                Clear
              </v-btn>
            
              <v-btn
                text
                class=""
                @click="searchs"
                :disabled="sloading"
                color="info"
              >
                Search
              </v-btn>
              
              </v-row
            ></v-card
          ></v-col
        >
      </v-row>
      <div v-for="(ban, i) in results" :key="i">
        <v-card outlined class="mt-2">
          <v-card-title>
            Ticket {{ ban.id }}
          </v-card-title>
          <v-card-subtitle class="mt-n6"> {{ ban.reason }}</v-card-subtitle>

          <v-btn
            color="info"
            :disabled="!ban.ttoken"
            @click="$router.push(`/ticket/${ban.ttoken}`)"
            class="elevation-0 ml-4 mt-1 mb-3"
          >
            Transcript
          </v-btn>
        
          <v-card outlined class="mx-4 mb-3">
            <v-card-title> Ticket info </v-card-title>
            <v-layout wrap>
              <div class="ml-4 mt-n4">
                <h3>Ticket author ID</h3>
                <p>{{  ban.author }}</p>
              </div>
              <div class="ml-4 mt-n4">
                <h3>ID of person who claimed</h3>
                <p>{{ ban.mod || "N/A"}}</p>
              </div>
              <div class="ml-4 mt-n4">
                <h3>Active</h3>
                <p>{{ ban.active }}</p>
              </div>
              <div class="ml-4 mt-n4">
                <h3>Topic</h3>
                <p>{{ ban.topic }}</p>
              </div>
              <div class="ml-4 mt-n4">
                <h3>Transcript ID</h3>
                <p>{{ ban.ttoken || 'N/A'}}</p>
              </div>
            </v-layout>
          </v-card></v-card
        >
      </div>
    </v-container>

    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {},
  data: () => ({
    search: {
      found: false,
      results: [],
      active: false,
      topic: "",
      userId: "",
      transcripted: false,
    },
    toast: {
      message: "",
      color: "success",
      open: false,
    },
    aresults: [],

    sloading: false,
  }),
  mounted() {
    this.$http.get("/tickets").then((res) => {
      this.aresults = res.data.tickets;
    });
  },
  computed: {
    results() {
      //check if search is empty
      if (!this.search.active) return this.aresults;
      return this.search.results;

    }
  },
  methods: {
    gettime: function (date) {
      let datex = new Date(date);
      if (datex.getTime() === 0) return "Never";
      return datex.toLocaleString("en-US", { timeZone: "America/New_York" });
    },
    getcur: function () {
      let current = new Date();
      return `${current.getFullYear()}-${(current.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${current.getDate()}`;
    },
    openpage: function (url) {
      window.open(url);
    },
    clearSearch() {
      this.search.found = false;
      this.search.active = false;
      this.search.topic = "";
      this.search.userId = "";
      this.search.transcripted = false;
    },
    async searchs() {
      this.sloading = true;
      this.search.active = true
      let req;
      try {
        req = await this.$http.post(`/tickets/search/`, {
          userId: this.search.userId,
          transcripted: this.search.transcripted,
          topic: this.search.topic,
        });
      } catch (e) {
        return;
      }

      this.search.results = req.data.tickets;
      this.sloading = false;
    },
  },
};
</script>
