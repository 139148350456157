import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: 'WHOOOP',
      pfp: 'https://t4.rbxcdn.com/a1c2e81e045aa1fca83a1fc8de6b6ddb',
      dark: true,
      otherInfo: Object,
      id: 1,
      isDeveloper: false,
      chosenOne: false,
    }, 
    disabled: ''
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setDisabled(state, disabled) {
      state.disabled = disabled
    },
    noLongerChosenOne(state) {
      state.user.chosenOne = false
    }
  },
  actions: {

  },
  modules: {

  }
})
